<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>PO No</th>
          <th>PO Date</th>
          <th>Supplier</th>
          <th>Project</th>
          <th>Department</th>
          <th>PO Type</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in poList.data" :key="i">
          <td>{{ item.po_number }}</td>
          <td>{{ item.date }}</td>
          <td>{{ item.contact_profile?.full_name }}</td>
          <td>{{ item.project?.name }}</td>
          <td>{{ item.department?.name }}</td>
          <td>{{ item.po_type }}</td>
          <td>{{ item.status }}</td>
          <td>
            <button class="btn btn-primary btn-sm" title="Edit PO"
                    @click="editPO(item)"
                    style="cursor: pointer"
            >
              <i class="fas fa-edit"></i>
            </button>
            <div class="btn-group">
              <button type="button" class="btn   dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="fa-solid fa-ellipsis-vertical"></i>
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <a @click="printPO(item)" class="dropdown-item" href="#">PO(Dynamic Sign)</a>
                <a @click="printPO(item,false)" class="dropdown-item" href="#">PO(Static Sign)</a>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-if="!poList.total" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import handleCompany from "@/services/modules/company";
import handleRequisitions from "@/services/modules/procurement/requisition";
import handleBarcode from "@/services/modules/barcode";
import poPdfPrinter from '@/services/utils/poPdfPrinter';
import { formatDate } from '@/services/utils/global';
import {inject, reactive, ref} from "vue";
import { useStore } from "vuex";
inject('showError')
const { fetchCompanyInfo } = handleCompany();
const { fetchSinglePO } = handleRequisitions();
const { fetchBarcode } = handleBarcode();
const { exportToPDF } = poPdfPrinter();
const routeList = {
  cs_po: "po-add",
  pr_po: "pr-po-add",
  new_po: "add-new-po"
}

const props  = defineProps({
  poList: Object,
});

let companyInfo = reactive({})
const route  = useRoute();
const router = useRouter();
const store = useStore();
let purchaseOrder = reactive({})
let barcodeQuery = ref('');

const barcode = ref('');
const qrcode = ref('');

function editPO(item) {
  let param = {
    companyId: route.params.companyId,
    moduleId : route.params.moduleId,
    menuId   : route.params.menuId,
    pageId   : route.params.pageId,
  }
  
  let query = route.query;
  query.poId = item.id;

  if(! routeList.hasOwnProperty(item.po_type)) {
    showError("Expected purchase order type not found")
    return;
  }

  router.push({
    name  : routeList[item.po_type],
    params: param,
    query : query
  })
}

async function printPO(item,dynamicSign = true) {

  const user = store.state.user.user;
  const companyQuery = `?company_id=${route.params.companyId}`;

  await fetchSinglePO(item.id, companyQuery).then((res) => {
    if (res.status) {
      
      purchaseOrder = res.data;
      
      let qrcodeData = JSON.stringify({
        po_number: purchaseOrder.po_number,
        po_date: purchaseOrder.po_date,
        created_by: user.name,
        created_date_time: formatDate(new Date())
      })
      
      barcodeQuery.value = `?code=${purchaseOrder.po_number}&qrcode=${qrcodeData}`;

    }
  })
    .then(() => {
      fetchBarcode(barcodeQuery.value).then((res) => {
        barcode.value = res.barcode;
        qrcode.value = res.qrcode
      }).then(() => {
        exportToPDF(companyInfo, purchaseOrder, barcode.value, qrcode.value, user.name, item.po_type,dynamicSign)
      })
    })
    .catch((err) => {
      console.log("Something went wrong");
    });

}

const setCompanyInfo = async () => {
  await fetchCompanyInfo(route.params.companyId).then((res) => {
    companyInfo = res.data;
  }).catch((error) => {
    console.log(error);
  });
}

setCompanyInfo();
</script>
