import pdfMake from 'pdfmake';
import handlePurchase from "@/services/modules/purchase";
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import figureFormatter from '@/services/utils/figureFormatter'
import { joinArrayOfString, chunkArray } from '@/services/utils/global';

const { format, parseISO } = require('date-fns');
const { commaFormat, currencyToWord } = figureFormatter();
const { formatCurrency } = handlePurchase();


pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}
const requisitionPdfPrinter = () => { 

    const exportToPDF = (company, po, barcode, qrcode, userName, poType = '',dynamicSign = true) => {
        const tableItems = po.purchase_general;
        
        var doc = {
            pageSize: 'A4',
            pageMargins: [ 30, 100, 30, 70 ],
            header: getHeader(company),
            footer: function (currentPage,pageCount){
                if (pageCount === currentPage){
                   return  getFooter(userName, qrcode)
                }
            },
            content: getContent(po, barcode, tableItems, poType,dynamicSign),

            styles : {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: po.po_number
            }
        }

        pushIntoTable(doc, po, tableItems, poType)
        pushIntoTableTotal(doc, po, tableItems)

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const formatDate = (date) => {
            var formattedDate = date.toISOString().split('T')[0];
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var amOrPm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12;
            var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;

            return formatToDdMmYy(formattedDate) + ' ' + formattedTime
    }
    const formatToDdMmYy = (date) => {
        var dat = date.split('-');
        return `${dat[2]}-${dat[1]}-${dat[0]}`;
    }
    const getHeader = (company) => {
            return {
                margin: [ 30, 10, 30, 10 ],
                columns: [
                    {
                        alignment: 'left',
                        image: company.logo64,
                        maxHeight: 60,
                    },

                    {
                        alignment: 'right',
                        stack: [
                            company.name,
                            'Address: ' + company.address,
                            'Phone: ' + company.phone,
                            'E-mail: ' + company.email
                        ]
                    }
                ]

            }
    }
    const getContent = (po, barcode, tableItems, poType,dynamicSign = true) => {
        return  [
                {
                    text: 'Purchase Order',
                    style: 'header',
                    alignment: 'center',
                    margin: [0, 0, 5, 10],
                    bold: true,
                    color: 'black',
                },

                {
                    margin: [0, 5, 0, 0],
                    alignment: 'justify',
                    columns: [
                        {
                            width: '50%',
                            stack: [
                                        {
                                            text: 'Supplier Details',
                                            color: 'black',
                                            bold: true,

                                        },

                                        {
                                            text: po.contact_profile?.full_name || '',
                                            alignment: 'left',
                                            width: '50%'
                                        },

                                        {
                                            text: [
                                                ...(getSupplierDetails(po.contact_profile, 'billing_address')
                                                    ? [
                                                        { text: 'Address: ', bold: true },
                                                        { text: getSupplierDetails(po.contact_profile, 'billing_address') }
                                                    ]
                                                    : [])
                                            ],
                                            width: '50%'
                                        },

                                        {
                                            text: [
                                                ...(getSupplierDetails(po.contact_profile, 'full_address')
                                                    ? [
                                                        { text: 'Address: ', bold: true },
                                                        { text: getSupplierDetails(po.contact_profile, 'full_address') }
                                                    ]
                                                    : [])
                                            ],
                                            width: '50%'
                                        },

                                        {
                                            text: [
                                                ...(getSupplierDetails(po.contact_profile, 'contact_person')
                                                    ? [
                                                        { text: 'Attention: ', bold: true },
                                                        { text: getSupplierDetails(po.contact_profile, 'contact_person') }
                                                    ]
                                                    : [])
                                            ],
                                            width: '50%'
                                        },
                            ]

                        },


                        {
                            alignment: 'right',
                            width: '50%',
                            stack: [
                                {
                                    text: [
                                        {
                                            text: 'Purchase Order Date: ',
                                            bold: true,
                                            margin: [0, 0, 10, 0]
                                        },

                                        {
                                            text: formatToDdMmYy(po.po_date),
                                        }
                                     ]
                                },

                                {
                                    text: [
                                        {
                                            text: 'Purchase Order No: ',
                                            bold: true,
                                            margin: [0, 0, 10, 0]
                                        },

                                        {
                                            text: po.po_number,
                                        }
                                 ]

                                },

                                {
                                    svg: barcode
                                },

                                po.requisition_numbers  &&
                                {
                                    text: [
                                        {
                                            text: 'PR No: ',
                                            bold: true,
                                            margin: [0, 0, 10, 0]
                                        },

                                        {
                                            text: po.requisition_numbers
                                        }
                                    ],

                                },

                                po.cs_requisition_numbers  &&
                                {
                                    text: [
                                        {
                                            text: 'PR No: ',
                                            bold: true,
                                            margin: [0, 0, 10, 0]
                                        },

                                        {
                                            text: po.cs_requisition_numbers
                                        }
                                    ],

                                },


                            ]
                        },
                    ]
                },

                {
                    alignment: 'justify',
                    margin: [0, 10],
                    text: [
                        { text: 'Dear Sir, \n Please refer to your offer and the subsequent discussion we had with you. Your offer is accepted at a cost negotiated price as mentioned below with mentioned terms & conditions in the purchase order for our project name '},
                        { text: getProjectNames(po), bold: true }
                    ]
                },

                {
                    style: 'tableExample',
                    margin: [0, 10, 0, 0],
                    table: {
                        widths: getTableWidth(tableItems),
                        body: []
                    },
                    layout: {
				        fillColor: function (rowIndex, node, columnIndex) {
                            return (rowIndex === 0) ? '#f3f2f7' : null;
                        },
                        hLineWidth: function (i, node) {
                            return 0.5;
                        },
                        vLineWidth: function (i, node) {
                            return 0.5;
                        }
			        }
		        },

                {
                    columns: [
                        {
                            width: '60%',
                            text: [
                                {
                                    text: 'In Words: ',
                                    bold: true
                                },

                                {
                                    text: getTotal(tableItems, 'total_amount') > 0.00 ? currencyToWord(
                                        getTotal(tableItems, 'total_amount')  + po.carrying_cost - po.adjustment_amount
                                    ) : '',
                                }
                            ]
                        },
                        
                        {
                            table: {
                                widths: ['*', '*'],
                                alignment: 'right',
                                body: [
                                       [
                                          { text: 'Total' },
                                          { text: commaFormat(getTotal(tableItems, 'total_amount')), alignment: 'right' }
                                        ],

                                    ...(po.adjustment_amount > 0
                                        ? [
                                            [
                                                { text: '(-) Special Discount' },
                                                { text: `(${commaFormat(po.adjustment_amount)})`, alignment: 'right' }
                                            ],
                                            [
                                                { text: 'GROSS TOTAL', bold: true },
                                                { text:  commaFormat( getTotal(tableItems, 'total_amount') - po.adjustment_amount), alignment: 'right' }
                                            ]
                                        ]
                                        : []),

                                    ...(po.carrying_cost > 0
                                        ? [
                                            [
                                                { text: '(+) Carrying Cost' },
                                                { text: commaFormat(po.carrying_cost), alignment: 'right' }
                                            ]
                                        ]
                                        : []),

                                    ...(po.tds > 0
                                        ? [
                                            [
                                                { text: '(+) TDS', bold: true },
                                                { text: commaFormat(po.tds), alignment: 'right' }
                                            ]
                                        ]
                                        : []),

                                    ...(po.tds > 0 || po.carrying_cost > 0
                                        ? [
                                            [
                                                { text: 'Grand Total', bold: true },
                                                { text: commaFormat(
                                                        ((getTotal(tableItems, 'total_amount')) + po.carrying_cost + po.tds) - (po.adjustment_amount > 0 ? po.adjustment_amount : 0)
                                                    ), alignment: 'right' }
                                            ]
                                        ]
                                        : []),
                                ]
                            },
                            layout: {
                                hLineWidth: function (i, node) {
                                    return 0.5;
                                },
                                vLineWidth: function (i, node) {
                                    return 0.5;
                                }
                            }
                        }
                    ],
                    margin: [0, 10]
                },

                {
                    alignment: 'justify',
                    margin: [0, 10],
                    text: [
                        { text: 'Terms & Conditions: \n', bold: true },
                        { 
                            margin: [10],
                            text: [
                                {text: getTerms(po.notes)}
                            ]
                        }
                    ]
                },

                {
                    alignment: 'justify',
                    margin: [0, 10],
                    text: [
                        { text: 'With Best Regards'}
                    ]
                },

                ...(dynamicSign
                    ? formatSignatureData(po)
                    : []),

               (!dynamicSign
                    ? formatStaticSignatureData()
                    : [])
            ]
    }


    const getTableWidth = (tableItems) => {
        const hasVatAmount = tableItems.find(item => item.vat_amount > 0);
        const hasDiscountAmount = tableItems.find(item => item.discount_amount > 0);

        const rowStructure = ['8%', '*', 'auto', 'auto', 'auto', 'auto']

        if(!! hasDiscountAmount || !! hasVatAmount) {
            rowStructure.push('auto');
        }
        if (!! hasVatAmount) {
            rowStructure.push('auto');
        }
        if (!! hasDiscountAmount) {
            rowStructure.push('auto');
        }

        return  rowStructure;

    }

    const getFooter = (userName, qrcode) =>{
        return {
            margin: [ 30, 0, 60, 30 ],
            columns: [
                {
                    columns: [
                        {
                            svg: qrcode
                        },

                        {
                            width: '90%',
                            alignment: 'left',
                            text: 'N.B: This is a system generated documents and requires no manual signature.',
                            margin: [5, 53, 0, 0],
                            fontSize: 8
                        }
                    ]

                },

                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },
                        {
                            text: userName
                        },
                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }

    const getSupplierDetails = (contact_profile, field) => {
        if (field === 'full_address' && contact_profile.contact_type === 'person'){
           return  contact_profile[field] ?? '';
        }
        const item = contact_profile.profile_items && contact_profile.profile_items.find(item => item.field === field);
        if(!item) return '';

        return item.value;
    }

    const dateTimeFormat = (inputDate) => {
        const date = parseISO(inputDate);

        const hasTimeInformation = date.getHours() !== 0 || date.getMinutes() !== 0 || date.getSeconds() !== 0;
        const formatString = hasTimeInformation ? 'dd-MMM-yy h:mm aa' : 'dd-MMM-yy';
        return format(date, formatString).replace(/(am|pm)/i, (match) => match.toUpperCase());
    }

    const formatStaticSignatureData = () => {

        let signatures = [ 'Prepared By', 'Reviewed By', 'Approved By' ];

        return {
            alignment: 'justify',
            absolutePosition: { x: 60, y: 750 },
            columns: signatures.map(getStaticSingleSignature)
        }
    }

    const getStaticSingleSignature = (title) => {
        return {
            width: '43%',
            stack: [
                {
                    canvas: [
                        {
                            type: 'line',
                            x1: 0,
                            y1: 0,
                            x2: 58,
                            y2: 0,
                            lineWidth: 1
                        }
                    ]
                },
                {
                    margin: [ 0, 5, 0, 0 ],
                    text: `${title} `,
                    color: 'black',
                    bold: true,
                    alignment: 'left',
                }
            ]
        }
    }

    const formatSignatureData = (po) => {
        let signatureData = [
            getSingleSignature('Prepared By', (po.user ? po.user.name : '' ), po.created_at),
        ];

        const signatures = po.workflow_approval_logs.map(log => {
            const approvalStep = log.workflow_general && log.workflow_general.approval_step;
            return getSingleSignature(approvalStep ? approvalStep.name : '', log.user.name, log.created_at)
        })

        signatureData = signatureData.concat(signatures);

        const chunkedData = chunkArray(signatureData, 4);


        return chunkedData.map(signatures => {

            return {
                alignment: 'justify',
                absolutePosition: { x: 30, y: 725 },
                columns: signatures
            }
        })
    }

    const getSingleSignature = (title, name, date) => {
        return {
            width: '25%',
            stack: [
                {
                    text: `${title}: `,
                    color: 'black',
                    bold: true,
                },

                {
                    text: name,
                    alignment: 'left',
                    width: '50%',
                },

                {
                    text: dateTimeFormat(date),
                    alignment: 'left',
                    width: '50%',
                },
            ]
        }
    }

    const getProjectNames = (po) => {
        if (po.project_id){
            return `${po.project.name} (${po.project.code})`;
        }
        const projects = po.purchase_general.map(item => {
            let project = (item.requisition_general && item.requisition_general.requisition_master) ? item.requisition_general.requisition_master.project : '';
            if(! project)  {
                project = (item.cs_general && item.cs_general.cs_master && item.cs_general.cs_master.requisition_master) ? item.cs_general.cs_master.requisition_master.project : '';
            }
            if(! project)  return '';
            return `${project.name} (${project.code})`;
        })
        const uniqueProjects = [...new Set(projects)];
        return joinArrayOfString(uniqueProjects);
    }

    const getTerms = (notes) => {
        let terms = '';
        const singleNote = notes.find(item => item.type === 'po_terms');
        if(!singleNote) return terms;

        for(let i = 0; i < singleNote.note.length; i++){
            terms += `${i + 1}. ${singleNote.note[i]} \n`;
        }

        return terms;
    }
    let slNumber = 0;

    const pushIntoTable = (doc, po, tableItems, poType) => {
        slNumber = 0;
        doc.content[3].table.body.push(getTableHeader(tableItems));
        const hasVatAmount = tableItems.find(item => item.vat_amount > 0);
        const hasDiscountAmount = tableItems.find(item => item.discount_amount > 0);

        tableItems.forEach(item => {
            const rowData = getRowData(po, item, poType,!!hasVatAmount,!!hasDiscountAmount);
            
            doc.content[3].table.body.push(rowData);
        });

        return doc.content[3].table.body;
    }

    const pushIntoTableTotal = (doc, po, tableItems) => {
        const hasVatAmount = tableItems.find(item => item.vat_amount > 0);
        const hasDiscountAmount = tableItems.find(item => item.discount_amount > 0);

        const data = [
            { text:  '' },
            {
                text:  'Total'
            },
            { text:  '' },
            { text:  '' },
            { text:  '' },
            { text:  commaFormat(getTotal(tableItems, 'amount')), alignment: 'right' },
        ]

        if (hasDiscountAmount && hasVatAmount) {
            data.push({ text:  `(${commaFormat(getTotal(tableItems, 'discount_amount'))})`, alignment: 'right' })
            data.push({ text:  commaFormat(getTotal(tableItems, 'vat_amount')), alignment: 'right' })
            data.push({ text:  commaFormat(getTotal(tableItems, 'total_amount')), alignment: 'right' })
            return doc.content[3].table.body.push(data);
        }
        if (hasDiscountAmount) {
            data.push({ text:  `(${commaFormat(getTotal(tableItems, 'discount_amount'))})`, alignment: 'right' })
            data.push({ text:  commaFormat(getTotal(tableItems, 'total_amount')), alignment: 'right' })
        }
        if (hasVatAmount) {
            data.push({ text:  commaFormat(getTotal(tableItems, 'vat_amount')), alignment: 'right' })
            data.push({ text:  commaFormat(getTotal(tableItems, 'total_amount')), alignment: 'right' })
        }

        return doc.content[3].table.body.push(data);

    }
    const getTableHeader = (tableItems) => {
        const hasVatAmount = tableItems.find(item => item.vat_amount > 0);
        const hasDiscountAmount = tableItems.find(item => item.discount_amount > 0);

        const headingRow = [
            { text: 'SL #' },
            { text: 'Item Description' },
            { text: 'Unit' },
            { text: 'Quantity', alignment: 'right' },
            { text: 'Unit Price', alignment: 'right' },
            { text: 'Amount', alignment: 'right' },
        ];

        if (!! hasVatAmount && !! hasDiscountAmount) {
            headingRow.splice(6, 0 , { text: 'Discount Amount', alignment: 'right' })
            headingRow.splice(7, 0 , { text: 'VAT Amount', alignment: 'right' })
            headingRow.splice(8, 0 , { text: 'Total Amount', alignment: 'right' })
            return headingRow;
        }

        if (!! hasDiscountAmount) {
            headingRow.splice(6, 0 , { text: 'Discount Amount', alignment: 'right' })
            headingRow.splice(7, 0 , { text: 'Total Amount', alignment: 'right' })
        }
        if (!! hasVatAmount ) {
            headingRow.splice(6, 0 , { text: 'VAT Amount', alignment: 'right' })
            headingRow.splice(7, 0 , { text: 'Total Amount', alignment: 'right' })
        }

        return headingRow;
    }

    const getItemDetails = (item, poType) => {
        
        if(item.is_product == '1' && poType == 'new_po'){
            return item.product ? item.product.name : ''
        }
         
        if(item.is_product == '1'){
            return item.requisition_general && item.requisition_general.product ? item.requisition_general.product.name : ''
        }
        
        return item.cs_general && item.cs_general.account_head ? item.cs_general.account_head.name : ''
    }

    const getRowData = (po, item, poType,hasVatAmount,hasDiscountAmount) => {
        slNumber += 1;

        let rowForInvoice = [
            {
              text: slNumber,
            },
            {
                stack: [
                    {
                        text: getItemDetails(item, poType),
                    },
                    {
                        text: item.description,
                    }
                ]
            },
            {
                text:  item.is_product == '1' ? item.requisition_general?.product?.description?.unit?.name : '-',
                alignment: 'right'
            },
            { text: item.is_product == '1' ? commaFormat(item.quantity) : '-', alignment: 'right' },
            { text: item.is_product == '1' ? commaFormat(item.rate) : '', alignment: 'right' },
            { text: commaFormat(item.amount), alignment: 'right' },
        ]
        if (hasDiscountAmount && hasVatAmount) {
            rowForInvoice.splice(6, 0 , { text: `(${commaFormat(item.discount_amount)})`, alignment: 'right' })
            rowForInvoice.splice(7, 0 , { text: commaFormat(item.vat_amount), alignment: 'right' })
            rowForInvoice.splice(8, 0 , { text: commaFormat(item.total_amount), alignment: 'right'})
            return rowForInvoice;
        }
        if (hasDiscountAmount) {
            rowForInvoice.splice(6, 0 , { text: `(${commaFormat(item.discount_amount)})`, alignment: 'right' })
            rowForInvoice.splice(7, 0 , { text: commaFormat(item.total_amount), alignment: 'right'})
        }
        if (hasVatAmount) {
            rowForInvoice.splice(6, 0 , { text: commaFormat(item.vat_amount), alignment: 'right' })
            rowForInvoice.splice(7, 0 , { text: commaFormat(item.total_amount), alignment: 'right'})
        }
        return rowForInvoice;
    }

    const getTotal = (data, column) => {
        let total = 0.00;
        if (data != undefined){
            data.forEach((item)=>{
            total += parseFloat(item[column]);
            })
        }
        return total;
    }

    return {
        exportToPDF
    }
}

export default requisitionPdfPrinter;
